import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    if (window.matchMedia("(min-width: 600px)")) {
      this.element.addEventListener("mouseover", (e) => {
        let percentageAcross = e.pageX / window.innerWidth;

        if (percentageAcross < 0.2) {
          clearInterval(this.scrollInterval);
          this.scrollInterval = setInterval(() => {
            let distanceToScroll = 40 * (0.2 - percentageAcross);
            this.element.scrollLeft -= distanceToScroll;
          }, 5);
        } else if (percentageAcross > 0.8) {
          clearInterval(this.scrollInterval);
          this.scrollInterval = setInterval(() => {
            let distanceToScroll = 40 * (percentageAcross - 0.8);
            this.element.scrollLeft += distanceToScroll;
          }, 5);
        } else {
          clearInterval(this.scrollInterval);
        }
      });

      this.element.addEventListener("mouseleave", (e) => {
        clearInterval(this.scrollInterval);
      });
    }
  }

  disconnect() {
    clearInterval(this.scrollInterval);
  }
}
