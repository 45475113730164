import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="settings-field-toggle"
export default class extends Controller {
  static targets = ["textField", "selectField", "button", "buttonIcon", "form"];

  async edit() {
    this.textFieldTarget.disabled = false;
    this.textFieldTarget.focus();
    this.textFieldTarget.classList.replace(
      "form-field",
      "form-field-activated",
    );
    this.buttonIconTarget.classList.replace("bi-pencil-square", "bi-save");
    this.buttonTarget.setAttribute(
      "data-action",
      "click->settings-field-toggle#save",
    );
  }

  async save() {
    this.buttonTarget.removeAttribute("type");
    this.formTarget.requestSubmit();
  }

  async editSelect() {
    this.buttonIconTarget.classList.replace("bi-pencil-square", "bi-save");
    this.buttonTarget.setAttribute(
      "data-action",
      "click->settings-field-toggle#save",
    );
    this.selectFieldTarget.disabled = false;
    this.selectFieldTarget.focus();
    this.selectFieldTarget.classList.toggle("select_visible");
    this.selectFieldTarget.style.appearance = "auto";
  }
}
