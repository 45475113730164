import { Controller } from "stimulus";
import Sortable, { MultiDrag } from "sortablejs";
import { Turbo } from "@hotwired/turbo-rails";
import { FetchRequest } from "@rails/request.js";

Sortable.mount(new MultiDrag());
export default class extends Controller {
  static targets = ["dragZone", "dropZone"];
  static values = {
    url: String,
    folderUrl: String,
  };
  sortableInstances = [];
  isDraggedOverDropzone = false;

  connect() {
    this.dragZoneTargets.map((element) => {
      const sortable = this.initializeSortable(element, true);
      this.sortableInstances.push(sortable);
    });

    this.dropZoneTargets.map((element) => {
      this.initializeDropzoneListeners(element);
      const sortable = this.initializeSortable(element, false);
      this.sortableInstances.push(sortable);
    });
  }

  initializeSortable(element, isDragZone) {
    return new Sortable(element, {
      onEnd: this.onEnd.bind(this),
      onStart: function (evt) {
        if (!isDragZone) {
          evt.from.classList.add("dropzone--og");
        }
      },
      removeCloneOnHide: false,
      multiDrag: isDragZone,
      selectedClass: "request-selected",
      dragClass: "request-dragging",
      ghostClass: "sortable-ghost",
      sort: false,
      group: {
        name: "folder",
        put: !isDragZone,
      },
      disabled: this.isTouchDevice(),
    });
  }

  initializeDropzoneListeners(dropZone) {
    dropZone.addEventListener("dragenter", this.onDragEnter.bind(this));
    dropZone.addEventListener("dragleave", this.onDragLeave.bind(this));
  }

  removeDropzoneListeners(dropZone) {
    dropZone.removeEventListener("dragenter", this.onDragEnter.bind(this));
    dropZone.removeEventListener("dragleave", this.onDragLeave.bind(this));
  }

  disconnect() {
    this.element.querySelectorAll(".request-selected").forEach((element) => {
      if (element) {
        element.classList.remove("request-selected");
      }
    });

    if (this.sortableInstances.length > 0) {
      this.sortableInstances.forEach((instance) => {
        if (instance) {
          instance.destroy();
        }
      });
    }

    this.dropZoneTargets.forEach((element) =>
      this.removeDropzoneListeners(element),
    );
  }

  onDragEnter(event) {
    if (
      !event.currentTarget.contains(event.relatedTarget) &&
      !event.currentTarget.classList.contains("dropzone--og")
    ) {
      event.currentTarget.classList.add("dropzone--active");
      this.isDraggedOverDropzone = true;
    }
  }

  onDragLeave(event) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      event.currentTarget.classList.remove("dropzone--active");
      this.isDraggedOverDropzone = false;
    }
  }

  onEnd(event) {
    event.from.classList.remove("dropzone--og");
    if (
      event.to.id &&
      this.isDraggedOverDropzone &&
      event.to.id !== event.target.id
    ) {
      this.handleItemsDropped(event);
      this.isDraggedOverDropzone = false;
    } else if (!this.isDraggedOverDropzone && event.to.id) {
      this.handleItemsReturned(event);
    }
  }

  handleItemsDropped(event) {
    let params, requestUrl;

    const movingFolder = event.target.closest(
      "[data-draggable-target='dropZone']",
    );
    if (movingFolder) {
      event.item.classList.add("d-none");
      params = { parent_id: event.to.id };
      requestUrl = this.folderUrlValue.replace("$placeholder", event.target.id);
    } else {
      const items = event.items.length > 0 ? event.items : [event.item];
      params = {
        folder_id: event.to.id,
        coverage_report_requests: items.map((item) => item.dataset.requestId),
      };
      requestUrl = this.urlValue;
    }
    this.moveRequests(requestUrl, params);
  }

  handleItemsReturned(event) {
    const oldItems =
      event.oldIndicies.length > 0
        ? event.oldIndicies
        : [{ index: event.oldIndex, multiDragElement: event.item }];

    oldItems.forEach((item) => {
      event.from.insertBefore(
        item.multiDragElement,
        event.from.children[item.index],
      );
    });
  }

  async moveRequests(url, params) {
    try {
      const request = new FetchRequest("POST", url, {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      const response = await request.perform();

      Turbo.visit(window.location.href, { action: "replace" });
      if (!response.ok) {
        throw new Error(`HTTP error ${response.status}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  isTouchDevice() {
    return window.matchMedia("(any-pointer: coarse)").matches;
  }
}
