import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";
import ClipboardJS from "clipboard";

// Should be used directly on the tab element itself in the HTML
// Connects to data-controller="modals"
export default class extends Controller {
  modal = null;

  async connect() {
    this.modal = new Modal(this.element);
    const modalName = this.element.getAttribute("aria-labelledby");

    if (
      this.element.classList.contains("show") ||
      window.location.href.indexOf(modalName) > -1
    ) {
      this.modal.show();
    }

    if (modalName?.includes("socialShareModal")) {
      new ClipboardJS("#clipboard-btn-" + this.element.id, {
        container: this.element,
      });
    }
  }

  linkCopied(event) {
    const stringToCopy = event.params.url;
    const shareModal = this.modal;
    const fadeOutAlertMethod = this.fadeOutAlert;
    setTimeout(async () => {
      // The use of navigator.clipboard requires a secure origin
      if (window.isSecureContext && navigator.clipboard) {
        await navigator.clipboard.writeText(stringToCopy).then(
          function () {
            shareModal.hide();
            const globalAlert = window.document.getElementById("global-alert");
            if (!(globalAlert === null)) {
              globalAlert.textContent = "Link copied to clipboard";
              globalAlert.classList.toggle("d-none");
              fadeOutAlertMethod(globalAlert, 2000);
            }
          },
          function (err) {
            console.error("Failed to write to clipboard: ", err);
          },
        );
      }
    });
  }

  fadeOutAlert(alert, fadeTime) {
    const fadeIntervalTime = 20;
    const fadeIntervals = fadeTime / fadeIntervalTime;
    const fadeIncrement = 1.0 / fadeIntervals;
    const fadeEffect = setInterval(function () {
      if (!alert.style.opacity) {
        alert.style.opacity = 1;
      }
      if (alert.style.opacity > 0) {
        alert.style.opacity -= fadeIncrement;
      } else {
        const bootstrapAlert = new bootstrap.Alert(alert);
        bootstrapAlert.close();
        clearInterval(fadeEffect);
      }
    }, fadeIntervalTime);
  }
}
