import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="navbar"
export default class extends Controller {
  async updateMobileNavStylingOnToggle() {
    this.element
      .querySelector(".navbar-toggler-icon")
      .classList.toggle("close-icon");

    const footerButtons = this.element.querySelectorAll(".footer-button");
    let updateButtonsPromise = footerButtons.forEach((button) => {
      if (button.classList.contains("opaqued")) {
        button.classList.toggle("opaqued");
      } else {
        button.classList.toggle("opaqued");
      }
    });
    updateButtonsPromise;
  }
}
