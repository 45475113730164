import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tooltipTrigger", "htmlToolTip"];
  static values = { interactive: { type: Boolean, default: true } };

  initialize() {
    if (this.hasTooltipTriggerTarget) {
      const interactive = this.interactiveValue;
      this.tippyInstance = tippy(this.tooltipTriggerTarget, {
        allowHTML: true,
        interactive: interactive,
        interactiveBorder: interactive ? 30 : 0,
        theme: "custom",
        content: this.hasHtmlToolTipTarget
          ? this.htmlToolTipTarget.innerHTML
          : this.tooltipTriggerTarget.getAttribute("data-tippy-content"),
        popperOptions: {
          modifiers: [
            {
              name: "flip",
              options: {
                fallbackPlacements: ["bottom", "top"],
              },
            },
          ],
        },
      });
      this.scrollListener = this.hideTooltip.bind(this);
      document.addEventListener("scroll", this.scrollListener);
    }
  }

  hideTooltip() {
    if (this.hasTooltipTriggerTarget) {
      this.tippyInstance.hide();
    }
  }

  disconnect() {
    if (this.hasTooltipTriggerTarget) {
      document.removeEventListener("scroll", this.scrollListener);
    }
  }
}
