import { Controller } from "stimulus";
import { ScrollSpy } from "bootstrap";

export default class extends Controller {
  static values = {
    offset: String,
  };

  connect() {
    this.boundScrollToNavItem = this.scrollToNavItem.bind(this);

    // Listen for the activation event of Bootstrap ScrollSpy
    window.addEventListener("activate.bs.scrollspy", this.boundScrollToNavItem);
    const dataSpyList = document.querySelectorAll('[data-bs-spy="scroll"]');
    dataSpyList.forEach((dataSpyEl) => {
      ScrollSpy.getOrCreateInstance(dataSpyEl).refresh();
    });
  }

  disconnect() {
    window.removeEventListener(
      "activate.bs.scrollspy",
      this.boundScrollToNavItem,
    );
  }

  scrollToAnchor(event) {
    event.preventDefault();
    const elementRect = document
      .querySelector(event.target.getAttribute("href"))
      .getBoundingClientRect();

    // take into account header height
    let topPosition = document.querySelector("header").offsetHeight;

    const offset = parseInt(this.offsetValue) || 0;
    if (elementRect) {
      window.scrollTo({
        top: elementRect.top + window.scrollY - offset - topPosition,
        behavior: "smooth",
      });
    }
  }

  scrollToTop(event) {
    const button = event.target;
    const container = this.findClosestContainer(button) || window;
    if (container && container.scrollHeight > container.clientHeight) {
      // Container has a max height, scroll within the container and bring it to the top
      container.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      // Container without a max height, scroll the entire window to the top
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  findClosestContainer(element) {
    // Find the closest parent div with a class containing "container"
    return element.closest('div[class*="container"]');
  }

  // Scroll horizontally to ensure the corresponding nav item is in view
  scrollToNavItem(e) {
    const targetNavItem = e.relatedTarget.closest("li");
    const container = e.relatedTarget.closest("nav");
    if (targetNavItem && container) {
      const targetLeftPosition = targetNavItem.getBoundingClientRect().left;
      const targetRightPosition = targetNavItem.getBoundingClientRect().right;
      const containerLeftPosition = container.getBoundingClientRect().left;
      const containerRightPosition = container.getBoundingClientRect().right;
      if (targetLeftPosition < containerLeftPosition) {
        container.scrollTo({
          behavior: "smooth",
          left: targetLeftPosition,
        });
      } else if (targetRightPosition > containerRightPosition) {
        const targetCenterPosition =
          targetLeftPosition + targetNavItem.offsetWidth / 2;
        container.scrollTo({
          behavior: "smooth",
          left: targetCenterPosition - container.offsetWidth / 2,
        });
      }
      targetNavItem.classList.add("active");
    }
  }
}
