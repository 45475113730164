import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["anchor"];
  static values = { offset: Number };

  connect() {
    setTimeout(() => {
      if (this.hasOffsetValue && window.location.href.includes("#")) {
        scrollBy(0, this.offsetValue);
      }
    }, 5);
  }

  stickyScroll() {
    if (this.hasAnchorTarget && document.querySelector("[id$='-tab']")) {
      let minDistanceFromTopPx = Math.abs(
        this.anchorTarget.getBoundingClientRect().top.valueOf()
      );
      let activeTarget = this.anchorTarget;

      this.anchorTargets.forEach((target) => {
        let distanceFromTop = target.getBoundingClientRect().top;
        if (distanceFromTop < minDistanceFromTopPx && distanceFromTop < 50) {
          minDistanceFromTopPx = Math.abs(distanceFromTop);
          activeTarget = target;
        }
      });

      document.querySelectorAll("[id$='-tab']").forEach((tab) => {
        tab.classList.remove("active");
      });
      document.getElementById(activeTarget.id + "-tab").classList.add("active");
    }
  }

  // the sticky header makes the browser's native scroll to anchor misaligned, so we have to scroll to the anchor manually
  // and offset with the height of the sticky header
  click(event) {
    let offsetValue = this.offsetValue;
    // scroll more on mobile
    if (window.innerWidth < 576) {
      offsetValue *= 2;
    }
    // scroll to the top of the page if it's the first tab
    if (event.target === document.querySelectorAll(".tabs .nav-link")[0]) {
      event.target.scrollIntoView(true);
    } else if (this.hasOffsetValue) {
      setTimeout(() => {
        document
          .getElementById(event.target.id.split("-")[0])
          .scrollIntoView(true);
        scrollBy(0, offsetValue);
      }, 5); // 5ms wait to ensure this happens after the browser's native scroll to anchor
    }
  }
}
