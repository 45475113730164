import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="issue"
export default class extends Controller {
  static targets = ["pages", "belowIssueContent", "endOfIssue", "panel"];
  static values = {
    issueNumber: String,
    path: String,
  };
  zoomed = false;

  connect() {
    this.loadPagesSequentially();
    this.panelTargets.forEach((panel) => {
      panel.addEventListener("contextmenu", (e) => e.preventDefault());
    });
  }

  // load first two comic pages sequentially and then the rest in parallel
  loadPagesSequentially() {
    this.lastLoadIndex = 0;
    const loadAllPages = () => {
      this.panelTargets.forEach((panel, index) => {
        if (panel.getAttribute("src") === null) {
          panel.setAttribute("src", panel.getAttribute("data-src"));
        }
      });
    };

    const loadNextImage = () => {
      if (
        this.lastLoadIndex === 2 ||
        this.panelTargets.length === this.lastLoadIndex
      ) {
        loadAllPages();
        this.showBelowIssueContent();
        return;
      }
      this.panelTargets[this.lastLoadIndex].addEventListener(
        "load",
        loadNextImage,
      );
      this.panelTargets[this.lastLoadIndex].setAttribute(
        "src",
        this.panelTargets[this.lastLoadIndex].getAttribute("data-src"),
      );
      this.panelTargets[this.lastLoadIndex].removeAttribute("data-src");
      this.lastLoadIndex += 1;
    };

    loadNextImage();
  }

  showBelowIssueContent() {
    if (this.hasBelowIssueContentTarget)
      this.belowIssueContentTarget.classList.remove("d-none");
  }

  doubleClick(event) {
    if (window.innerWidth < 992) {
      const yPosition =
        document.documentElement.scrollTop || document.body.scrollTop;

      let yOffset = yPosition + event.clientY;
      let xOffset = event.clientX;

      this.pagesTarget.style.transformOrigin = "0px 0px";
      this.pagesTarget.style.transform = this.zoomed ? "none" : "scale(2)";
      this.zoomed = !this.zoomed;

      if (this.zoomed) yOffset = yOffset * 2 - 300;
      else yOffset = yOffset / 2 - 300;
      window.scrollTo({ left: xOffset, top: yOffset, behavior: "instant" });
    }
  }
}
