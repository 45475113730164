import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal", "form", "selectedFolder"];

  openModal(event) {
    this.formTarget.action = event.params.moveUrl;
    this.selectedFolderTarget.value = event.params.currentFolderId;

    const modalElement = this.modalTarget;
    const modal = new Modal(modalElement);
    modal.show();
  }
}
