import { Controller } from "stimulus";

// Connects to data-controller="prefill-input"
export default class extends Controller {
  static targets = ["question", "textarea"];

  setQuestion(event) {
    const question = event.target.dataset.question;
    this.textareaTarget.value = question;
  }
}
