import Reveal from "@stimulus-components/reveal";

export default class extends Reveal {
  static targets = ["focus", "replace", "scroll"];

  show() {
    super.show();
    this.postRevealActions();
  }

  toggle() {
    super.toggle();
    this.postRevealActions();
  }

  postRevealActions() {
    if (this.hasScrollTarget) {
      this.scrollTarget.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    if (this.hasFocusTarget) {
      this.focusTarget.focus();
    }
    if (this.hasReplaceTarget) {
      this.replaceTarget.classList.add("d-none");
    }
  }
}
