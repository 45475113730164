import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  async connect() {
    if (window.location.hash.length > 0) {
      this.element.value = window.location.hash;
    } else {
      this.element.value = "";
    }
  }
}
