import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="sort"
export default class extends Controller {
  reverse(event) {
    const elementId = event.params.parent;
    const parent = document.getElementById(elementId);
    for (let i = 1; i < parent.childNodes.length; i++) {
      parent.insertBefore(parent.childNodes[i], parent.firstChild);
    }
  }
}
