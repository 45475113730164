import { Controller } from "@hotwired/stimulus";
import { Tab } from "bootstrap";

// Should be used at the tab list level
// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["default"];

  async initialize() {
    if (this.element.querySelector('[data-bs-toggle="tab"]')) {
      const targetToShow = window.location.href.split("#")[1];
      const fallbackTarget = window.location.href.split("tab=")[1];
      const target = targetToShow || fallbackTarget;
      const tabs = Array.from(
        this.element.querySelectorAll('[data-bs-toggle="tab"]'),
      );
      const tabToShow = tabs.find(
        (tab) => tab.getAttribute("data-bs-target") === `#${target}`,
      );
      let tabElement;
      if (tabToShow) {
        tabElement = tabToShow;
      } else if (this.hasDefaultTarget) {
        tabElement = this.defaultTarget;
      } else {
        tabElement = tabs[0];
      }
      new Tab(tabElement).show();
    }
  }

  click(event) {
    const tabName = event.currentTarget.getAttribute("data-bs-target");
    const newUrl = window.location.href.split("#")[0] + tabName;
    history.pushState(null, null, newUrl);
  }
}
