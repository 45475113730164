import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal", "form"];

  openModal(event) {
    this.formTarget.action = event.params.submitUrl;

    const modalElement = this.modalTarget;
    const modal = new Modal(modalElement);
    modal.show();
  }

  reset() {
    this.formTarget.reset();
  }
}
