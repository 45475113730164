import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

// Connects to data-controller="coverage-request-delete"

export default class extends Controller {
  static values = {
    // The action templates has a value of `$placeholder` that can be substituted with a coverage request ID.
    deleteEverythingActionTemplate: String,
    deleteSourceActionTemplate: String,
  };
  static targets = [
    "deleteEverythingModal",
    "deleteSourceMaterialModal",
    "coverageRequestTitle",
    "deleteEverythingForm",
    "deleteSourceForm",
  ];

  deleteEverything(event) {
    this.updateModalValues(event);
    const modal = new Modal(this.deleteEverythingModalTarget);
    modal.show();
  }
  deleteSource(event) {
    this.updateModalValues(event);
    const modal = new Modal(this.deleteSourceMaterialModalTarget);
    modal.show();
  }

  updateModalValues(event) {
    this.coverageRequestTitleTargets.forEach((element) => {
      element.innerHTML = event.params.coverageRequestTitle;
    });
    this.deleteEverythingFormTarget.action =
      this.deleteEverythingActionTemplateValue.replace(
        "$placeholder",
        event.params.coverageRequestId,
      );
    this.deleteSourceFormTarget.action =
      this.deleteSourceActionTemplateValue.replace(
        "$placeholder",
        event.params.coverageRequestId,
      );
  }
}
