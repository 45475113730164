import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["repoNav", "repoContainer", "repoBody"];

  navLayout(event) {
    const currRepoBody = this.repoBodyTargets.find((target) =>
      target.checkVisibility(),
    );

    if (!(this.repoContainerTarget && this.repoNavTarget && currRepoBody)) {
      return;
    }

    const scrollThreshold = this.repoNavTarget.getBoundingClientRect().top + 60;
    const isPastThreshold =
      scrollThreshold > currRepoBody.getBoundingClientRect().top;

    if (isPastThreshold) {
      this.repoContainerTarget.classList.add("repo-scroll");
    } else {
      this.repoContainerTarget.classList.remove("repo-scroll");
    }
    if (window.scrollY === 0) {
      this.repoContainerTarget.classList.remove("repo-scroll");
    }
  }

  goBack(event) {
    event.preventDefault();
    history.back();
  }
}
