import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal", "deleteButton", "folderName"];

  openModal(event) {
    const deleteButtonForm = this.deleteButtonTarget.closest("form");
    deleteButtonForm.action = event.params.deleteUrl;

    const folderName = event.params.folderName;
    this.folderNameTarget.innerText = `"${folderName}"`;

    const modalElement = this.modalTarget;
    const modal = new Modal(modalElement);
    modal.show();
  }
}
