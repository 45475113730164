import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import { Modal } from "bootstrap";

export default class extends Controller {
  static values = {
    refreshUrl: String,
  };

  static targets = [
    "addModal",
    "manageModal",
    "templateFormPrefillText",
    "templateFormSourceQuestionId",
  ];

  connect() {
    this.boundResetModals = this.resetModals.bind(this);
    this.element.addEventListener("hidden.bs.modal", this.boundResetModals);
  }

  disconnect() {
    this.element.removeEventListener("hidden.bs.modal", this.boundResetModals);
  }

  hideModals() {
    const addModal = Modal.getInstance(this.addModalTarget);
    if (addModal) {
      addModal.hide();
    }
    const manageModal = Modal.getInstance(this.manageModalTarget);
    if (manageModal) {
      manageModal.hide();
    }
  }

  resetModals(event) {
    // Only reset modals if the closed modal is one of the modals we care about.
    if (
      event.target !== this.addModalTarget &&
      event.target !== this.manageModalTarget
    ) {
      return;
    }
    const url = this.refreshUrlValue;
    // This effectively reset the manage modal so that it is back in an index state instead of a edit state.
    if (url) {
      Turbo.visit(url, { action: "replace" });
    }

    const addModalForm = this.addModalTarget.querySelector("form");
    if (addModalForm) {
      addModalForm.reset();
    }
    const manageModalForm = this.manageModalTarget.querySelector("form");
    if (manageModalForm) {
      manageModalForm.reset();
    }
  }

  saveQuestion(event) {
    const params = event.params;
    const sourceQuestionId = params.sourceQuestionId;
    const questionText = params.questionText;
    if (questionText && this.hasTemplateFormPrefillTextTarget) {
      this.templateFormPrefillTextTarget.value = questionText;
    }

    // Sometimes this is triggered from the manage modal, which needs to be manually hidden.
    if (this.hasManageModalTarget) {
      const modal = Modal.getInstance(this.manageModalTarget);
      if (modal) {
        modal.hide();
      }
    }

    const modal = new Modal(this.addModalTarget);
    modal.show();

    // Update the hidden field with the source question id so that on a successful save, the "save question" button is hidden.
    // Hide the back button if there is a source question id.
    if (sourceQuestionId && this.hasTemplateFormSourceQuestionIdTarget) {
      this.templateFormSourceQuestionIdTarget.value = sourceQuestionId;
      document.getElementById("form-back-btn").classList.add("d-none");
    } else {
      document.getElementById("form-back-btn").classList.remove("d-none");
    }
  }

  //Once a question is successfully saved - always show the manage modal.
  afterSaveQuestion() {
    const modal = Modal.getInstance(this.addModalTarget);
    const manageModal = Modal.getOrCreateInstance(this.manageModalTarget);
    if (modal) {
      modal.hide();
    }
    if (manageModal) {
      manageModal.show();
    }
  }
}
