import { Controller } from "stimulus";
import _debounce from "lodash/debounce";

// Allow more than 1 sticky element to pin below the header.
// Accounts for browser resize & alert banners
const DEBOUNCE_TIME_IN_MS = 30;
export default class extends Controller {
  debouncedReevaluateStickyLayout = _debounce(() => {
    this.updateStickyPositions();
  }, DEBOUNCE_TIME_IN_MS);

  connect() {
    //fix for firefox - element heights are accurate after a 200ms delay
    if (navigator.userAgent.toLowerCase().includes("firefox")) {
      setTimeout(() => {
        this.updateStickyPositions();
      }, 200);
    } else {
      this.updateStickyPositions();
    }

    this.boundDebounce = this.debouncedReevaluateStickyLayout.bind(this);
    window.addEventListener("resize", this.boundDebounce);

    // If there are alert banners, reevaluate positions when they close via bootstrap event
    const alertBanners = document.getElementById("alert-banners");
    alertBanners?.querySelectorAll(".alert").forEach((alert) => {
      alert.addEventListener(
        "closed.bs.alert",
        () => {
          this.handleAlertUpdate();
        },
        { once: true },
      );
    });

    // reevaluate positions when they close via turbo event
    document.addEventListener(
      "turbo:frame-render",
      this.handleAlertUpdate.bind(this),
    );
    document.addEventListener(
      "turbo:load",
      this.reconnectEventListeners.bind(this),
    );
  }

  disconnect() {
    window.removeEventListener("resize", this.boundDebounce);
    document.removeEventListener(
      "turbo:frame-render",
      this.handleAlertUpdate.bind(this),
    );
    document.removeEventListener("turbo:load", this.reconnectEventListeners);
  }

  reconnectEventListeners() {
    window.removeEventListener("resize", this.debouncedReevaluateStickyLayout);
    window.addEventListener("resize", this.debouncedReevaluateStickyLayout);
    this.updateStickyPositions();
  }

  handleAlertUpdate() {
    this.updateStickyPositions();
    this.checkRemainingAlerts();
  }

  checkRemainingAlerts() {
    if (!document.querySelector("#alert-banners .alert:not(.d-none)")) {
      window.removeEventListener("resize", this.boundDebounce);
    }
  }

  updateStickyPositions() {
    const stickyElements = document.querySelectorAll(".avail-sticky-top");
    let topPosition = document.querySelector("header").offsetHeight;
    stickyElements.forEach((element) => {
      element.style.top = `${topPosition}px`;
      topPosition += element.offsetHeight;
    });
  }
}
