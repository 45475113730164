import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  navigate(event) {
    const link = event.target.closest("a");
    if (link) {
      event.preventDefault();
      event.stopPropagation();
      Turbo.visit(link.href);
    }
  }
}
