import { Controller } from "stimulus";

export default class extends Controller {
  toggle(event) {
    const parentEl = event.currentTarget.parentElement;
    const expandedClass = "faq__item--expanded";
    if (parentEl.classList.contains(expandedClass)) {
      parentEl.classList.remove(expandedClass);
    } else {
      parentEl.classList.add(expandedClass);
    }
  }
}
