import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.heroAdvanceInterval = setInterval(this.advanceHero, 8000); // Milliseconds
  }

  disconnect() {
    clearInterval(this.heroAdvanceInterval);
  }

  advanceHero() {
    let heroCards = document.getElementsByClassName("hero");
    for (var i = 0; i < heroCards.length; i++) {
      if (heroCards[i].classList.contains("active")) {
        heroCards[i].classList.remove("active");
        heroCards[i].classList.add("minimized");
        heroCards[(i + 1) % 3].classList.add("active");
        heroCards[(i + 1) % 3].classList.remove("minimized");
        break;
      }
    }
  }
}
