import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["social", "profileImage", "name", "button"];

  connect() {
    this.largeBreakpointPx = 992;
    this.shrinkHero();
  }

  shrinkHero() {
    if (this.nameTarget.innerText.length > 35) {
      this.shrinkName();
    }
    if (window.innerWidth >= this.largeBreakpointPx) {
      let heightPx = Math.max(110, 208 - window.scrollY * 5);
      this.element.style.height = heightPx + "px";

      if (heightPx < 150) {
        this.socialTarget.classList.add("d-sm-none");
        this.shrinkName();

        if (this.hasButtonTarget)
          this.buttonTarget.classList.replace("button--lg", "button--md");
      } else {
        this.socialTarget.classList.remove("d-sm-none");
        this.unshrinkNameIfNotTooLong();
        if (this.hasButtonTarget)
          this.buttonTarget.classList.replace("button--md", "button--lg");
      }

      let profileImageSize = Math.max(60, 160 - window.scrollY * 5);
      this.profileImageTarget.style.height = profileImageSize + "px";
      this.profileImageTarget.style.width = profileImageSize + "px";
    }
  }

  resetHero() {
    if (window.innerWidth < this.largeBreakpointPx) {
      this.element.removeAttribute("style");
      this.profileImageTarget.removeAttribute("style");
      this.unshrinkNameIfNotTooLong();
    }
  }

  shrinkName() {
    this.nameTarget.style.fontSize = "2.2rem";
    this.nameTarget.style.lineHeight = "2.5rem";
  }

  unshrinkNameIfNotTooLong() {
    if (this.nameTarget.innerText.length <= 35) {
      this.nameTarget.removeAttribute("style");
    }
  }
}
