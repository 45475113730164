// app/javascript/controllers/view_more_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["expandBtn"];

  connect() {
    this.checkContentHeight();
  }

  checkContentHeight() {
    const wrapper = this.element;
    const button = this.expandBtnTarget;
    if (wrapper.scrollHeight > wrapper.clientHeight) {
      button.classList.remove("d-none");
    } else {
      button.classList.add("d-none");
    }
  }

  toggle() {
    const { element: wrapper, expandBtnTarget: button } = this;
    wrapper.classList.toggle("expanded");
  }
}
