import { Controller } from "@hotwired/stimulus";
import mixpanel from "mixpanel-browser";

// Should be used directly on the tab element itself in the HTML
// Connects to data-controller="copy-paste"
export default class extends Controller {
  // If present, the controller will temporarily update the innerHTML of this target with the statusSuccess value when copying is successful.
  // Otherwise, a global alert will be used.
  static targets = ["copyStatus"];

  static values = {
    statusSuccess: String,
    statusDefault: String,
  };

  copyInput(event) {
    const stringToCopy = event.params.input;

    const updateStatusMethod = this.updateStatus;
    const fadeOutAlertMethod = this.fadeOutAlert;

    // for custom copy status ui
    let statusTarget;
    let successStr;
    let defaultStr;

    if (
      this.hasCopyStatusTarget &&
      this.hasStatusSuccessValue &&
      this.hasStatusDefaultValue
    ) {
      statusTarget = this.copyStatusTarget;
      successStr = this.statusSuccessValue;
      defaultStr = this.statusDefaultValue;
    }

    setTimeout(async () => {
      // The use of navigator.clipboard requires a secure origin
      if (window.isSecureContext && navigator.clipboard) {
        await navigator.clipboard.writeText(stringToCopy).then(
          function () {
            if (!(window === null) && !(window.document === null)) {
              if (statusTarget && successStr && defaultStr) {
                updateStatusMethod(statusTarget, successStr, defaultStr, 2000);
              } else {
                const globalAlert =
                  window.document.getElementById("global-alert");
                if (!(globalAlert === null)) {
                  globalAlert.textContent = "Copied to clipboard";
                  globalAlert.classList.toggle("d-none");
                  fadeOutAlertMethod(globalAlert, 2000);
                }
              }
            }
          },
          function (err) {
            console.error("Failed to write to clipboard: ", err);
          },
        );
      }
    });
    const contextParamsJson = event.params.contextParams; // json blob of additional context params
    mixpanel.track("Clicked Copy", contextParamsJson);
  }

  updateStatus(target, successStr, defaultStr, delay) {
    // change status to str
    target.innerHTML = successStr;
    // reset status
    setInterval(() => {
      target.innerHTML = defaultStr;
    }, delay);
  }

  fadeOutAlert(alert, fadeTime) {
    const fadeIntervalTime = 20;
    const fadeIntervals = fadeTime / fadeIntervalTime;
    const fadeIncrement = 1.0 / fadeIntervals;
    const fadeEffect = setInterval(function () {
      if (!alert.style.opacity) {
        alert.style.opacity = 1;
      }
      if (alert.style.opacity > 0) {
        alert.style.opacity -= fadeIncrement;
      } else {
        alert.classList.add("d-none");
        alert.style.opacity = 1;
        clearInterval(fadeEffect);
      }
    }, fadeIntervalTime);
  }
}
