import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["gateModal", "contentNav", "contentFooter", "backToTop"];
  oldScroll = 0;

  connect() {
    if (this.hasGateModalTarget)
      new bootstrap.Modal(this.gateModalTarget).show();
  }

  onScroll() {
    if (this.hasContentNavTarget && this.hasContentFooterTarget) {
      if (
        this.oldScroll > window.scrollY ||
        window.innerHeight + window.scrollY >= document.body.offsetHeight
      ) {
        this.contentNavTarget.classList.add("scrolled-up");
        this.contentNavTarget.classList.remove("scrolled-down");

        this.contentFooterTarget.classList.add("scrolled-up");
        this.contentFooterTarget.classList.remove("scrolled-down");
        if (
          (document.body.scrollTop > 2000 ||
            document.documentElement.scrollTop > 2000) &&
          this.oldScroll > window.scrollY
        ) {
          this.backToTopTarget.classList.add("shown");
        } else {
          this.backToTopTarget.classList.remove("shown");
        }
      } else if (window.scrollY > 10 && window.innerWidth < 600) {
        this.contentNavTarget.classList.add("scrolled-down");
        this.contentNavTarget.classList.remove("scrolled-up");

        this.contentFooterTarget.classList.add("scrolled-down");
        this.contentFooterTarget.classList.remove("scrolled-up");
        this.backToTopTarget.classList.remove("shown");
      }
    }

    this.oldScroll = window.scrollY;
  }

  backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.backToTopTarget.classList.remove("shown");
  }
}
