import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "contentType",
    "textColumnContainer",
    "scriptNotes",
    "bookNotes",
    "contentTitle",
    "requestTitle",
    "repositorySelect",
    "folderSelect",
  ];

  static values = {
    folderOptionsByRepo: Object,
  };

  connect() {
    this.selectContentType({ target: this.contentTypeTarget });
  }

  selectContentType(event) {
    const contentType = event.target.value;

    this.scriptNotesTarget.classList.toggle("d-none", contentType !== "script");
    this.bookNotesTarget.classList.toggle("d-none", contentType !== "book");
    this.textColumnContainerTarget.classList.toggle(
      "d-none",
      contentType !== "book",
    );
  }

  async selectRepository(event) {
    let selectedRepoId = this.repositorySelectTarget.value;
    this.updateFolderOptions(
      this.folderOptionsByRepoValue[selectedRepoId],
      this.folderSelectTarget,
    );
  }

  updateFolderOptions(options, target) {
    this.removeFolderOptions(target);

    options.forEach((option) => {
      let optionElement = document.createElement("option");
      optionElement.text = option[0];
      optionElement.value = option[1];
      target.add(optionElement);
    });
  }

  removeFolderOptions(target) {
    let i,
      len = target.length - 1;

    for (i = len; i >= 0; i--) {
      target.remove(i);
    }
  }

  contentTitleFocusOut(event) {
    const contentTitle = event.target.value;
    const currentRequestTitle = this.requestTitleTarget.value;
    if (currentRequestTitle === "") {
      this.requestTitleTarget.value = contentTitle;
    }
  }
}
