import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sliderValue"];

  connect() {
    this.sliderValueTarget.textContent = "0.2";
  }

  update(event) {
    this.sliderValueTarget.textContent = event.target.value;
  }
}
