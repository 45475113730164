import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "scrollContainer",
    "modality",
    "numOfContent",
    "lengthOfContent",
    "lengthOfContentContainer",
    "hasTranscriptsContainer",
    "lengthOfContentSlider",
    "lengthOfContentSliderOutput",
    "lengthOfContentSliderMin",
    "lengthOfContentSliderMax",
    "numOfContentSlider",
    "numOfContentSliderOutput",
    "numOfContentSliderMin",
    "numOfContentSliderMax",
    "genresContainer",
  ];

  static values = {
    lastScrollTop: Number,
    selectedContentType: String,
    selectedContentId: String,
    selectedHasTranscriptId: String,
  };

  connect() {
    this.lastScrollTopValue = 0;
  }

  // SCROLLING FUNCTIONS
  onFormScroll(event) {
    const continueEl = document.getElementById("corpus-continue");
    continueEl?.classList.add("continue--show");
  }

  onWindowScroll(event) {
    const continueEl = document.getElementById("corpus-continue");
    continueEl?.classList.remove("continue--show");
  }

  // FORM FIELD FUNCTIONS
  selectContentType(event) {
    this.selectedContentTypeValue = event.target.value;
    // update modality value
    this.modalityTarget.value = this.getModality(this.selectedContentTypeValue);
    // reset slide values
    this.numOfContentSliderTarget.value = 0;
    this.numOfContentSliderOutputTarget.innerHTML = 0;
    this.lengthOfContentSliderTarget.value = 0;
    this.lengthOfContentSliderOutputTarget.innerHTML = 0;

    // update num of content field
    this.numOfContentTarget.innerHTML = this.getNumOfContentString(
      this.selectedContentTypeValue,
    );
    this.updateNumOfContentRange(this.selectedContentTypeValue);

    // update length of content field
    if (this.getUnit(this.selectedContentTypeValue)) {
      this.lengthOfContentTarget.innerHTML = this.getLengthOfContentString(
        this.selectedContentTypeValue,
      );
      this.updateLengthOfContentRange(this.selectedContentTypeValue);
      this.lengthOfContentContainerTarget.classList.remove("d-none");
    } else {
      this.lengthOfContentContainerTarget.classList.add("d-none");
    }

    // show or hide has transcripts field
    if (this.isAudioOrVideo(this.selectedContentTypeValue)) {
      this.hasTranscriptsContainerTarget.classList.remove("d-none");
    } else {
      this.hasTranscriptsContainerTarget.classList.add("d-none");
    }

    this.selectOption(event.target, this.selectedContentIdValue);
    this.selectedContentIdValue = event.target.id;

    if (this.selectedContentTypeValue === "photography") {
      this.lengthOfContentContainerTarget.classList.add("d-none");
      this.lengthOfContentSliderTarget.value = 1;
    } else {
      this.lengthOfContentSliderTarget.value = 0;
      this.lengthOfContentContainerTarget.classList.remove("d-none");
    }

    if (
      this.selectedContentTypeValue === "book" ||
      this.selectedContentTypeValue === "comic_art"
    ) {
      // hide the genres field for books and comic art
      this.genresContainerTarget.classList.add("d-none");
    } else {
      this.genresContainerTarget.classList.remove("d-none");
    }
  }

  selectHasTranscript(event) {
    this.selectOption(event.target, this.selectedHasTranscriptIdValue);
    this.selectedHasTranscriptIdValue = event.target.id;
  }

  // GENERAL UI FUNCTIONS
  selectOption(currOption, prevOptionId) {
    if (prevOptionId) {
      const prevSelectedEl = document.getElementById(prevOptionId);
      prevSelectedEl.parentElement.classList.remove("option-card--selected");
    }

    const parentEl = currOption.parentElement;
    parentEl.classList.add("option-card--selected");
  }

  next(event) {
    event.preventDefault();
    const scrollInterval = document.getElementById("calculator").clientHeight;
    this.scrollContainerTarget.scrollBy(0, scrollInterval);
  }

  back(event) {
    event.preventDefault();
    const scrollInterval = document.getElementById("calculator").clientHeight;
    this.scrollContainerTarget.scrollBy(0, -scrollInterval);
  }

  // FIELD HELPERS

  // for configuring fields
  getNumOfContentString(contentType) {
    switch (contentType) {
      case "photography":
        return "How many photos do you have?";
      case "video":
        return "How many videos have you created?";
      case "podcast":
        return "How many podcast episodes have you recorded?";
      case "movie":
        return "How many movies have you made?";
      case "book":
        return "How many books have you written?";
      case "writing":
        return "How many pieces have you written?";
      case "comic_art":
        return `How many books have you drawn?`;
      default:
        return `How many items have you created?`;
    }
  }

  getLengthOfContentString(contentType) {
    const length_string_prefix = "On average, ";
    switch (contentType) {
      case "video":
        return `${length_string_prefix} how many minutes is each video?`;
      case "podcast":
        return `${length_string_prefix} how many minutes is each podcast episode?`;
      case "movie":
        return `${length_string_prefix} how many minutes is each movie?`;
      case "book":
        return `${length_string_prefix} what is the word count of your books?`;
      case "writing":
        return `${length_string_prefix} what is the word count of each piece?`;
      case "comic_art":
        return `${length_string_prefix} how many pages are in each book?`;
      case "photography":
        return `How many photos do you have?`;
      default:
        return `${length_string_prefix} how long are your items?`;
    }
  }

  updateNumOfContentRange(contentType) {
    const range = this.getNumOfContentRange(contentType);
    this.updateRange(this.numOfContentSliderTarget, range);
    this.numOfContentSliderMinTarget.innerHTML = range.min;
    if (range.max < 1000) {
      this.numOfContentSliderMaxTarget.innerHTML = `${range.max}+`;
    } else {
      this.numOfContentSliderMaxTarget.innerHTML = `${this.formatNumberWithK(range.max)}+`;
    }
  }

  updateLengthOfContentRange(contentType) {
    const range = this.getLengthOfContentRange(contentType);
    this.updateRange(this.lengthOfContentSliderTarget, range);
    this.lengthOfContentSliderMinTarget.innerHTML = range.min;
    if (range.max < 1000) {
      this.lengthOfContentSliderMaxTarget.innerHTML = `${range.max}+`;
    } else {
      this.lengthOfContentSliderMaxTarget.innerHTML = `${this.formatNumberWithK(range.max)}+`;
    }
  }

  formatNumberWithK(number) {
    if (number >= 1000) {
      return (number / 1000).toFixed(0) + "k";
    } else {
      return number.toString();
    }
  }

  updateRange(rangeEl, { min, max, step }) {
    rangeEl.min = min;
    rangeEl.max = max;
    rangeEl.step = step;
  }

  // CONTENT TYPE HELPERS
  getModality(ip_type) {
    switch (ip_type) {
      case "podcast":
        return "audio";
      case "movie":
      case "video":
        return "videos";
      case "book":
      case "writing":
        return "text";
      case "comic_art":
      case "photography":
        return "image";
      default:
        return undefined;
    }
  }

  /**
   * Helper to return modality unit based on content type
   * @param contentType
   * @returns {string}
   */
  getUnit(contentType) {
    switch (contentType) {
      case "podcast":
      case "video":
      case "movie":
        return "minutes";
      case "book":
      case "writing":
        return "words";
      case "comic_art":
        return "frames";
      case "photography":
        return "photos";
      default:
        return undefined;
    }
  }

  getDescription(contentType, isMultiple = false) {
    switch (contentType) {
      case "photography":
        return this.pluralize("photo", isMultiple);
      case "video":
      case "podcast":
      case "movie":
      case "book":
        return this.pluralize(contentType, isMultiple);
      case "comic_art":
        return this.pluralize("comic book", isMultiple);
      case "writing":
      default:
        return this.pluralize("piece", isMultiple);
    }
  }

  pluralize(str, isMultiple) {
    return isMultiple ? str + "s" : str;
  }

  isAudioOrVideo(ip_type) {
    const av_types = ["movie", "video", "podcast"];
    return av_types.includes(ip_type);
  }

  getNumOfContentRange(contentType) {
    switch (contentType) {
      case "photography":
        return {
          min: 0,
          max: 100000,
          step: 1000,
        };
      case "video":
        return {
          min: 0,
          max: 5000,
          step: 10,
        };
      case "podcast":
        return {
          min: 0,
          max: 2000,
          step: 10,
        };
      case "movie":
        return {
          min: 0,
          max: 200,
          step: 1,
        };
      case "book":
        return {
          min: 0,
          max: 50,
          step: 1,
        };
      case "comic_art":
        return {
          min: 0,
          max: 200,
          step: 1,
        };
      case "writing":
        return {
          min: 0,
          max: 1000,
          step: 10,
        };
      default:
        return undefined;
    }
  }

  getLengthOfContentRange(contentType) {
    switch (contentType) {
      case "video":
        return {
          min: 0,
          max: 200,
          step: 1,
        };
      case "podcast":
        return {
          min: 0,
          max: 200,
          step: 1,
        };
      case "movie":
        return {
          min: 0,
          max: 200,
          step: 1,
        };
      case "book":
        return {
          min: 1000,
          max: 150000,
          step: 1000,
        };
      case "comic_art":
        return {
          min: 0,
          max: 100,
          step: 1,
        };
      case "writing":
        return {
          min: 0,
          max: 10000,
          step: 100,
        };
      case "photography":
        return {
          min: 0,
          max: 1,
          step: 1,
        };
      default:
        return undefined;
    }
  }

  isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
