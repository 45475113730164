import { Controller } from "@hotwired/stimulus";
import Uppy from "@uppy/core";
import Dashboard from "@uppy/dashboard";
import XHR from "@uppy/xhr-upload";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";

export default class extends Controller {
  static values = { uploadUrl: String };

  connect() {
    new Uppy({
      allowMultipleUploadBatches: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: [".zip"],
        maxTotalFileSize: 5 * 1024 * 1024 * 1024, // 5GB
      },
    })
      .use(Dashboard, {
        target: this.element,
        trigger: ".s3-upload-open",
        closeAfterFinish: true,
        proudlyDisplayPoweredByUppy: false,
        showProgressDetails: true,
        note: "Upload/replace the current sample. Must be a .zip file, currently limited to 5GB.",
      })
      .use(XHR, {
        endpoint: this.uploadUrlValue,
        method: "PUT",
        formData: false,
      })
      .on("complete", this.complete.bind(this));
  }

  complete() {
    window.location.reload();
  }
}
