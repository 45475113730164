import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["subPromptContainer"];
  static values = {
    publicationId: String,
    showBannerLoadCount: Number,
    incrementLoadCount: Boolean,
  };

  connect() {
    document.querySelector(".issue-body").classList.add("issue-body--current");
    this.incrementIssueLoads();
    this.boundOnHide = this.onHideOffcanvas.bind(this);
    this.boundOnShow = this.onShowOffcanvas.bind(this);
    const myOffcanvas = document.getElementById("series-drawer");
    myOffcanvas.addEventListener("hidden.bs.offcanvas", this.boundOnHide);
    myOffcanvas.addEventListener("show.bs.offcanvas", this.boundOnShow);
  }

  onHideOffcanvas() {
    document.querySelector(".issue-body").style.marginRight = null;
  }

  onShowOffcanvas() {
    const navWidth = document.getElementById("series-drawer").offsetWidth;
    document.querySelector(".issue-body").style.marginRight = navWidth + "px";
  }

  onScroll() {
    let minDistanceFromTopPx = Math.abs(
      document
        .querySelector(".issue-body--current")
        .getBoundingClientRect()
        .top.valueOf()
    );

    let currentIssue = document.querySelector(".issue-body--current");
    let newIssue = currentIssue;

    document.querySelectorAll(".issue-body").forEach((issue) => {
      let distanceFromTop = issue.getBoundingClientRect().top;
      if (distanceFromTop < minDistanceFromTopPx && distanceFromTop < 50) {
        minDistanceFromTopPx = Math.abs(distanceFromTop);
        newIssue = issue;
      }
    });

    if (newIssue !== currentIssue) {
      document.querySelectorAll(".issue-body").forEach((issue) => {
        issue.classList.remove("issue-body--current");
      });
      document.querySelectorAll("#series-drawer .issue").forEach((issue) => {
        issue.classList.remove("issue--current");
      });
      document
        .querySelectorAll("#mobile-series-drawer .issue")
        .forEach((issue) => {
          issue.classList.remove("issue--current");
        });
      newIssue.classList.add("issue-body--current");
      document
        .querySelector("#series-drawer #" + newIssue.parentNode.id)
        .classList.add("issue--current");
      document
        .querySelector("#mobile-series-drawer #" + newIssue.parentNode.id)
        .classList.add("issue--current");
      this.incrementIssueLoads();
    }
  }

  incrementIssueLoads() {
    if (!this.incrementLoadCountValue) {
      return;
    }
    const loadsKey = `publication_${this.publicationIdValue}_issue-loads`;
    let loads = localStorage.getItem(loadsKey);
    if (!loads) {
      loads = 0;
    }
    loads++;
    localStorage.setItem(loadsKey, loads);

    if (
      this.hasSubPromptContainerTarget &&
      loads === this.showBannerLoadCountValue
    ) {
      this.subPromptContainerTarget.classList.remove("d-none");
    }
  }
}
