import { Controller } from "@hotwired/stimulus";
import mixpanel from "mixpanel-browser";

// See _social_sharing_modal.html.erb for example usage
// Should be used directly on the tab element itself in the HTML
// Connects to data-controller="track"
export default class extends Controller {
  track(event) {
    const eventName = event.params.eventName;
    const payload = event.params.payload;
    mixpanel.track(eventName, payload);
  }

  trackSocialShareClick(event) {
    const name = event.params.name;
    const type = event.params.type;
    const index = event.params.index;
    const destination = event.params.destination;
    mixpanel.track(name, {
      "content-type": type,
      "content-index": index,
      "share-destination": destination,
    });
  }

  trackStoreTabClicked(event) {
    const creatorId = event.params.creatorId;
    const creatorHandle = event.params.creatorHandle;
    mixpanel.track("Clicked Creator Store Tab", {
      creator_id: creatorId,
      creator_handle: creatorHandle,
    });
  }

  clickedPromptPrefill(event) {
    const buttonText = event.target.innerText;
    const reportId = event.params.reportId;
    mixpanel.track("Clicked Q&A prefill prompt", {
      report_id: reportId,
      prompt_type: buttonText,
    });
  }
}
