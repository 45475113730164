import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal", "parentId", "name"];

  connect() {
    super.connect();
    this.boundFocusName = this.focusName.bind(this);
    this.modalTarget.addEventListener("shown.bs.modal", this.boundFocusName);
  }

  disconnect() {
    this.modalTarget.removeEventListener("shown.bs.modal", this.boundFocusName);
  }

  openModal(event) {
    this.parentIdTarget.value = event.params.parentId;

    const modalElement = this.modalTarget;
    const modal = new Modal(modalElement);

    modal.show();
  }

  focusName() {
    this.nameTarget.focus();
    this.nameTarget.select();
  }
}
