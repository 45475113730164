import { Controller } from "stimulus";

// Connects to data-controller="tags"
export default class extends Controller {
  static targets = ["tagContainer", "tagInput", "tagList"];

  connect() {
    this.tagInputTarget.addEventListener("keydown", (event) => {
      if (event.key === "Enter" || event.key === ",") {
        event.preventDefault();
        this.addTag();
      }
    });
  }

  addTag() {
    const tagValue = this.tagInputTarget.value.trim();
    if (tagValue !== "") {
      const tagElement = document.createElement("span");
      tagElement.dataset.tagsValue = tagValue;
      tagElement.classList.add(
        "badge",
        "badge--tertiary",
        "rounded-pill",
        "d-inline-flex",
        "align-items-center",
        "fw-normal",
        "me-1",
        "mb-1",
      );
      tagElement.innerHTML = `
        ${tagValue}
        <button class="material-icons-outlined fs-7 btn p-0 ms-1" type="button" data-action="click->tags#removeTag">close</button>
      `;
      this.tagContainerTarget.appendChild(tagElement);
      this.tagInputTarget.value = "";
      this.updateTagsInput();
    }
  }

  removeTag(event) {
    const tagElement = event.currentTarget.closest("[data-tags-value]");
    tagElement.remove();
    this.updateTagsInput();
  }

  updateTagsInput() {
    const tagsArray = Array.from(this.tagContainerTarget.children).map(
      (tagElement) => tagElement.dataset.tagsValue,
    );
    this.tagListTarget.value = tagsArray.toString();
  }
}
