import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["saveNote", "star"];

  static values = {
    starRating: Number,
  };

  connect() {
    super.connect();
    this.resetRating();
  }

  saveText(event) {
    event.preventDefault();
    this.saveNoteTarget.click();
  }

  resetRating() {
    const currentRating = this.starRatingValue * 2;
    this.starTargets.forEach((star, index) => {
      star.classList.remove("hover");
      if (index < currentRating) {
        star.classList.remove("default");
        star.classList.add("checked");
      } else {
        star.classList.remove("checked");
        star.classList.add("default");
      }
    });
  }

  hoverRating(event) {
    const hoveredStarIndex = this.starTargets.indexOf(event.target);
    this.starTargets.forEach((star, index) => {
      star.classList.remove("checked");
      if (index <= hoveredStarIndex) {
        star.classList.remove("default");
        star.classList.add("hover");
      } else {
        star.classList.remove("hover");
        star.classList.add("default");
      }
    });
  }
}
