import { Application } from "@hotwired/stimulus";
import Reveal from "@stimulus-components/reveal";
import TextareaAutogrow from "stimulus-textarea-autogrow";
import Clipboard from "@stimulus-components/clipboard";
import { registerControllers } from "stimulus-vite-helpers";
import Notification from "@stimulus-components/notification";
import { installErrorHandler } from "@appsignal/stimulus";

const productionFlag =
  import.meta.env.RAILS_ENV === "production" ||
  import.meta.env.RAILS_ENV === "staging";

const application = Application.start();

if (productionFlag && window.appsignal) {
  installErrorHandler(window.appsignal, application);
}

application.register("reveal", Reveal);
application.register("textarea-autogrow", TextareaAutogrow);
application.register("clipboard", Clipboard);
application.register("notification", Notification);

// Configure Stimulus development experience
application.debug = import.meta.env.RAILS_ENV === "development";
window.Stimulus = application;

const controllers = import.meta.glob("./*_controller.js", { eager: true });
registerControllers(application, controllers);
