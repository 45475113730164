import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal", "form", "folderName"];

  openModal(event) {
    this.formTarget.action = event.params.renameUrl;
    this.folderNameTarget.value = event.params.folderName;

    const modalElement = this.modalTarget;
    const modal = new Modal(modalElement);
    modal.show();
  }
}
