import { Tab } from "bootstrap";
import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="user-settings"
export default class extends Controller {
  static targets = ["subscriptions"];

  async switchTabs() {
    const subscriptionsTab = new Tab(this.subscriptionsTarget);
    subscriptionsTab.show();
  }
}
