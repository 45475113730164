import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["submit", "userInputText"];
  static values = { resourceName: String };

  connect() {
    this.submitTarget.disabled = true;
  }

  check(event) {
    const userTextInput = this.userInputTextTarget.value.toLowerCase();
    const confirmText = this.resourceNameValue;

    this.submitTarget.disabled = userTextInput !== confirmText;
  }
}
