import { Controller } from "@hotwired/stimulus";
// Imitates a link & navigates to the URL specified in the element's dataset.
export default class extends Controller {
  navigate(event) {
    const link = event.currentTarget.dataset.url;
    if (link) {
      event.preventDefault();
      event.stopPropagation();
      Turbo.visit(link);
    }
  }
}
